<template>
  <div class="row" v-if="loading">
    Loading...
  </div>
  <div class="row m-0 px-lg-4" v-else>
    <div class="col-12 px-lg-5">
      <div class="row m-0">
        <div class="col-md-3 p-1" v-for="(ue, i) in enquiries" :key="ue.i" :ref='"card_" + i'>
          <card :enq="ue"></card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'AllJourneys',
  components: {
    card: () => import('@/components/Card')
  },
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.getTravelPlanner().then(() => this.getEnquiries()).then(enqs => {
      this.loading = false
    })
  },
  computed: {
    ...mapGetters([
      'enquiries'
    ])
  },
  methods: {
    ...mapActions([
      'getEnquiries',
      'getTravelPlanner'
    ])
  }
}
</script>
<style scoped>
</style>
